@import "../../node_modules/bootstrap/scss/functions";

$primary: #001851 !default;
$secondary: #f3710A !default;

$font-size-base: 0.9rem; 

$btn-color: #fff !default;

$enable-negative-margins: true;

$font-family-sans-serif: Roboto;

@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/variables-dark";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";

@import "../../node_modules/bootstrap/scss/bootstrap";

th {
	text-transform: uppercase;
}

.btn, .btn:hover, .btn:focus, .btn:visited, .btn:active {
	color: white;
}

.btn-primary.btn:hover {
	background-color: #001851b6 !important;
	border-color: #001851b6 !important;
}

.btn-outline-light.btn:hover {
	background-color: #495A83 !important;
}

.btn-secondary.btn:hover {
	background-color: #f3730ac9 !important;
}

input:-webkit-autofill, input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s !important;
}

@page {	
	margin-bottom: 10mm;
	margin-top: 10mm;
}

@page :first {
	margin-top: 0;
}